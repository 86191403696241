body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*
	old
	background-image: url('https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjExMzk2fQ&auto=format&fit=crop&w=1350&q=80');
	*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.widget {
  text-align: left;
  background-color: rgba(255, 255, 255, 0.4);
  margin-top: 0px;
  margin-bottom: 15px;
  border-radius: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: black;
}

.widget-settings {
  color: black;
}

.notification {
  text-align: left;
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 5px;
  margin-bottom: 0%;
  border-radius: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.hide-notification {
  visibility: hidden;
  opacity: 0;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.main-container {
  margin-top: 5px;
}

.notification-section {
  color: black;
  background-color: rgba(255, 255, 255, 0);
  padding-bottom: 10px;
  border-radius: 10px;
}

.notification-title {
}

.notification-date {
  font-style: italic;
}

.notification-description {
}

.page-header > h1 {
  color: black;
  margin-bottom: 0px;
}

/* accordion stuff */
.accordion-button {
  background-color: rgb(248, 249, 250, 0.7);
}
.notification-provider-list-accordion {
  background-color: rgb(248, 249, 250, 0.3) !important;
}
.provider-list-accordion-sublist {
  width: 100% !important;
}

button:hover {
  background-color: rgb(255, 255, 255, 0.8);
  color: black;
}
input:hover {
  background-color: rgb(255, 255, 255, 0.8);
  color: black;
}
select:hover {
  background-color: rgb(255, 255, 255, 0.8);
  color: black;
}

.dissmiss-btn {
  text-align: right;
}

.main-navbar {
  background-color: rgb(248, 249, 250, 0.6) !important;
}
.public-user-card {
  color: black;
}

/*overrides for different sizes */
@media (min-width: 200px) and (max-width: 600px) {
  /*
  Mobile logic
  */
  body {
    /* background-image: url("https://images.unsplash.com/photo-1546514355-7fdc90ccbd03?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2734&q=80"); */
    background-image: url("./assets/mobile-bg.jpg");
    background-size: cover;
    color: whitesmoke;
  }
  .notification-image {
    width: 100%;
    border-radius: 10px;
  }
  .notification-section-header {
    color: whitesmoke;
  }
  .notification {
    background-color: rgba(255, 255, 255, 0.85);
  }
  .notification-provider-list {
    color: whitesmoke;
  }
  .notification-provider {
    color: black;
  }
}
@media (min-width: 600px) {
  body {
    background-image: url("./assets/desktop_bg.jpg");
    color: whitesmoke;
  }
  .notification-image {
    max-width: 100%;

    border-radius: 10px;
  }
  .notification-section-header {
    color: whitesmoke;
  }
  .notification-provider-list {
    color: whitesmoke;
  }
  .notification-provider {
    color: black;
  }
}
